






































































































































import router from "@/router";
import { computed, defineComponent } from "@vue/composition-api";
import VehicleCard from "@/components/vehicle/VehicleCard/VehicleCard.vue";
import { vehicleFacade } from "@/store/modules/vehicle/vehicle.facade";
import { ERoutes } from "@/core/dataTypes/enum";
import { i18n } from "@/locales/i18n";
import { getLanguageStaticRoute } from "@/core/utils/router.util";
import { commonFacade } from "@/store/modules/common/common.facade";

export default defineComponent({
  name: "VehicleListPage",
  components: {
    VehicleCard,
  },
  setup() {
    const vehicleListLoading = computed(
      vehicleFacade.hdCustomerVehicleListLoading
    );
    const contactCategoryLink = computed(commonFacade.contactCategory);
    const vehicleMaxNumber = computed(vehicleFacade.hdCustomerVehicleMaxNumber);
    const vehicleList = computed(vehicleFacade.hdCustomerVehicleList);
    const navigateToViewNewPage = () => {
      router.push(
        getLanguageStaticRoute(`${ERoutes.VEHICLE}/${ERoutes.VEHICLE_NEW}`)
      );
    };
    vehicleFacade.getHdCustomerVehicleList();
    return {
      vehicleMaxNumber,
      vehicleList,
      vehicleListLoading,
      navigateToViewNewPage,
      contactCategoryLink,
    };
  },
  metaInfo() {
    return {
      titleTemplate: `${i18n.tc("VEHICLE_LIST_PAGE.PAGE_TITLE")} | %s`,
    };
  },
});
